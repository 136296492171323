<template>
  <div>
    <component
      :is="computedComponentName"
      v-model="shouldShowModal"
      :close-on-content-click="false"
      :min-width="computedComponentName === 'v-menu' ? 240 : null"
      :max-width="computedComponentName === 'v-dialog' ? 300 : null"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="d-flex align-center justify-between filter-title text-truncate" :class="{ 'font-weight-bold': isFilterApplied }" v-on="on" v-bind="attrs">
          <div class="d-flex align-center flex-grow-1">
            <v-icon left> people </v-icon>

            <span class="text-truncate"> Team Members </span>
          </div>
          <v-icon> expand_more </v-icon>
        </div>
      </template>
      <v-card>
        <v-card-title class="d-md-none"> Include Team Members? </v-card-title>

        <v-card-text class="pt-md-3" :class="{ 'black--text': isThemeLight }">
          <v-checkbox v-model="form.value" hide-details label="Include Team Members" class="mt-0" @change="submitForm"></v-checkbox>
        </v-card-text>
      </v-card>
    </component>
  </div>
</template>

<script>
// used throughout the code
const filterName = "includeTeamMembers"

const originalForm = () => ({
  value: false
})

export default {
  name: "FilterIncludeTeamMembers",

  data: () => ({
    form: originalForm(),

    shouldShowModal: false
  }),

  computed: {
    // show the filter as a dialog for mobile dialogs
    computedComponentName() {
      return this.isDesktopDevice ? "v-menu" : "v-dialog"
    },

    // get a list of all of them
    appliedFilters() {
      return this.$store.getters["admin/users/findFiltersByType"](filterName)
    },

    // Used to bold the filter name text
    isFilterApplied() {
      return Boolean(this.appliedFilters.length)
    }
  },

  methods: {
    /**
     * Takes an item and generates the text
     */
    computeFilterText() {
      return `Include Team Members`
    },

    /**
     * Deletes all applied filters and disables the input
     */
    async submitForm() {
      // Remove all other filters for this type
      await this.$store.dispatch("admin/users/removeFilters", filterName)

      // only add if the value is true
      if (this.form.value) {
        this.$store.dispatch("admin/users/addFilter", {
          type: filterName,
          data: {
            color: "lightblue lighten-5",
            icon: "people",
            iconColor: "lightblue",
            text: this.computeFilterText(),
            inputs: this.form
          }
        })
      }
    },

    /**
     * Delete the entry from vuex store
     *
     * @param {Object} item
     */
    removeFilterItem(item) {
      this.$store.dispatch("admin/users/removeFilter", item.id)
    }
  },

  mounted() {
    // subscribe to window event when the chip is closed
    window.addEventListener("removeFilter", (e) => {
      // if it's not the one we want, don't execute the code
      if (e.detail.module !== "admin/users" || e.detail.item.type !== filterName) return

      this.form.value = false
    })
  }
}
</script>
